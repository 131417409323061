import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

import { getIdentifier } from 'styles/panelsStyles';

export const useAddGroupAssigneesPanelViewStyles = createUseStyles(
  (theme: ThemeState) => ({
    mainWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      gap: '1rem',

      height: '100%',
      padding: '1rem',
    },
    header: {
      display: 'grid',
      gap: '0.25rem',
    },
    title: {
      ...getIdentifier(theme),

      minWidth: 0,
      marginBottom: 0,
    },
    counterText: {
      marginBottom: 0,

      fontSize: '0.75rem',
      color: theme['secondary-color'],
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gap: '0.5rem',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.5rem',
    },
  })
);
