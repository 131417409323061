import React from 'react';
import { useIntl } from 'react-intl';
import { ClassPermissionUsersLimitAlertProps } from './ClassPermissionUsersLimitAlert.types';
import { LimitAlert } from 'components/LimitAlert';

export const ClassPermissionUsersLimitAlert = ({
  usersCount,
  usersLimit,
}: ClassPermissionUsersLimitAlertProps) => {
  const intl = useIntl();

  const errorMessage = intl.formatMessage(
    {
      id: 'objectClasses.permissionSets.usersLimitError',
      defaultMessage:
        'Maximum of {itemsLimit} assigned {itemsLimit, plural, one {user} other {users}} has been reached.',
    },
    { itemsLimit: usersLimit }
  );
  const warningMessage = intl.formatMessage(
    {
      id: 'objectClasses.permissionSets.usersLimitWarning',
      defaultMessage:
        'A permission set can have up to {itemsLimit} {itemsLimit, plural, one {user} other {users}} assigned. This currently has {count}.',
    },
    { itemsLimit: usersLimit, count: usersCount }
  );

  return (
    <LimitAlert
      itemsCount={usersCount}
      itemsLimit={usersLimit}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
};
