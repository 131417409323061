import React from 'react';
import { ClassPermissionGroupsLimitAlertProps } from './ClassPermissionGroupsLimitAlert.types';
import { useIntl } from 'react-intl';
import { LimitAlert } from 'components/LimitAlert';

export const ClassPermissionGroupsLimitAlert = ({
  groupsCount,
  groupsLimit,
}: ClassPermissionGroupsLimitAlertProps) => {
  const intl = useIntl();

  const errorMessage = intl.formatMessage(
    {
      id: 'objectClasses.permissionSets.groupsLimitError',
      defaultMessage:
        'Maximum of {itemsLimit} assigned user {itemsLimit, plural, one {group} other {groups}} has been reached.',
    },
    { itemsLimit: groupsLimit }
  );
  const warningMessage = intl.formatMessage(
    {
      id: 'objectClasses.permissionSets.groupsLimitWarning',
      defaultMessage:
        'A permission set can have up to {itemsLimit} user {itemsLimit, plural, one {group} other {groups}} assigned. This currently has {count}.',
    },
    { itemsLimit: groupsLimit, count: groupsCount }
  );

  return (
    <LimitAlert
      itemsCount={groupsCount}
      itemsLimit={groupsLimit}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
};
