import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetAssignedUsersCount,
  getObjectClassPermissionSetGroupsCount,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { PermissionAssigneeTabKey } from 'utils/Enums/PermissionAssigneeTab';
import { setSidebarData } from 'store/actions/flexLayoutActions';

export const useManageAssigneesPanelTabs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    [FlexLayoutWindows.ClassPermissionsOwners]: sidebarData,
  } = useSelector(getSidebarData);
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );
  const selectedPermissionSetId = selectedPermissionSet?.id?.toString() ?? '';
  const assignedUsersCount =
    useSelector(
      getObjectClassPermissionSetAssignedUsersCount(selectedPermissionSetId)
    ) ?? 0;
  const assignedGroupsCount =
    useSelector(
      getObjectClassPermissionSetGroupsCount(selectedPermissionSetId)
    ) ?? 0;

  const usersTabText = intl.formatMessage(
    {
      id: 'objectClass.manageAssignees.tabs.users',
      defaultMessage: 'Users ({count})',
    },
    { count: assignedUsersCount }
  );

  const groupsTabText = intl.formatMessage(
    {
      id: 'objectClass.manageAssignees.tabs.groups',
      defaultMessage: 'User groups ({count})',
    },
    { count: assignedGroupsCount }
  );

  const onTabChange = (tabKey: PermissionAssigneeTabKey) => {
    dispatch(
      setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
        ...sidebarData,
        activeTab: tabKey,
      })
    );
  };

  return {
    activeTab: sidebarData?.activeTab,
    usersTab: {
      key: PermissionAssigneeTabKey.Users,
      text: usersTabText,
    },
    groupsTab: {
      key: PermissionAssigneeTabKey.Groups,
      text: groupsTabText,
    },
    onTabChange,
  };
};
