import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { getObjectClassSelectedPermissionSet } from 'store/selectors/objectClassPermissionsSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import AddObjectClassOwner from '../../AddObjectClassOwner';
import { ManageAssigneesPanel } from '../../ManageAssigneesPanel';
import ManageOwnersPanel from '../../ManageOwnersPanel';
import { PanelMode } from 'utils/Enums/PanelMode';
import { AddPermissionSetUsersPanel } from '../../AddPermissionSetUsersPanel';
import { AddPermissionSetGroupsPanel } from '../../AddPermissionSetGroupsPanel';
import { PermissionAssigneeTabKey } from 'utils/Enums/PermissionAssigneeTab';

const Owners = () => {
  const intl = useIntl();

  const {
    [FlexLayoutWindows.ClassPermissionsOwners]: {
      mode = PanelMode.Manage,
      activeTab = PermissionAssigneeTabKey.Users,
    } = {},
  } = useSelector(getSidebarData);
  const {
    availableComponents,
    flexLayoutMethods: { onRenameTab },
  } = useFlexLayoutContext();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const Panel = useCallback(() => {
    if (mode === PanelMode.Adding) {
      if (!selectedPermissionSet?.id) {
        return <AddObjectClassOwner />;
      }

      switch (activeTab) {
        case PermissionAssigneeTabKey.Users:
          return <AddPermissionSetUsersPanel />;
        case PermissionAssigneeTabKey.Groups:
          return <AddPermissionSetGroupsPanel />;
      }
    }

    return selectedPermissionSet?.id ? (
      <ManageAssigneesPanel />
    ) : (
      <ManageOwnersPanel />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, selectedPermissionSet]);

  const tabNames = useMemo(
    () => ({
      [PanelMode.Adding]: {
        owners: intl.formatMessage({
          id: 'misc.addOwners',
          defaultMessage: 'Add owners',
        }),
        assignees: intl.formatMessage({
          id: 'misc.addAssignees',
          defaultMessage: 'Add assignees',
        }),
      },
      [PanelMode.Manage]: {
        owners:
          availableComponents[FlexLayoutWindows.ClassPermissionsOwners].name,
        assignees: intl.formatMessage({
          id: 'misc.manageAssignees',
          defaultMessage: 'Manage assignees',
        }),
      },
    }),
    [availableComponents, intl]
  );

  useEffect(() => {
    const tabName = selectedPermissionSet?.id
      ? tabNames[mode].assignees
      : tabNames[mode].owners;

    onRenameTab(FlexLayoutWindows.ClassPermissionsOwners, tabName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, selectedPermissionSet]);

  return <Panel />;
};

export default Owners;
