import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAlert2Styles = createUseStyles((theme: ThemeState) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    padding: '8px 12px',

    '&.ant-alert-error': {
      backgroundColor: theme['error-bg-light'],

      '& .ant-alert-icon, & .ant-alert-message': {
        color: theme['error-color'],
      },
    },
    '&.ant-alert-warning': {
      backgroundColor: theme['danger-color-light'],

      '& .ant-alert-icon, & .ant-alert-message': {
        color: theme['avatar-color-2'],
      },
    },
    '&.ant-alert-info': {
      backgroundColor: theme['info-bg'],

      '& .ant-alert-icon, & .ant-alert-message': {
        color: theme['primary-color'],
      },
    },

    '& .ant-alert-icon': {
      position: 'initial',
      flexShrink: 0,

      width: '1.125rem',
      height: '1.125rem',
    },

    '& .ant-alert-message': {
      fontSize: '0.75rem',
    },
  },
}));
