import React from 'react';
import { Alert as AntAlert } from 'antd';
import clsx from 'clsx';
import { useAlert2Styles } from './Alert2.styles';
import { Alert2Props } from './Alert2.types';
import { ExclamationMarkSquare } from 'components/Icon';
import { ALERT_TESTID } from 'utils/testIds';

/**
 * It's a component that will slowly replace old Alert component to unify styling and colours
 * of alerts accross the ALX app.
 */
export const Alert2 = ({
  message,
  className,
  type = 'warning',
  banner = true,
}: Alert2Props) => {
  const styles = useAlert2Styles();

  return (
    <AntAlert
      className={clsx(styles.alert, className)}
      type={type}
      message={message}
      banner={banner}
      icon={<ExclamationMarkSquare minWidth={0} />}
      data-testid={ALERT_TESTID[type]}
    />
  );
};
