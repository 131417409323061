import {
    getArrowWrapperStyles,
    getArrowsWrapperStyles,
    getInputNumberStyles,
    getInputNumberWrapperStyles,
} from 'components/lib/InputNumber/styles';
import { getLneTextAreaStyles } from 'components/LineCountTextarea/styles';
import { getDisabledInputStyle } from 'hooks/useGlobalStyle';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
    ARROWS_WRAPPER_ID,
    ARROW_DOWN_ID,
    ARROW_UP_ID,
    /*LINE_TEXT_AREA_ID,
    CODE_NAME_BASIC_ID,
    CODE_NAME_DETAILED_ID,*/
} from 'utils/elementsIds';
import { textAreaWrapper } from 'components/lib/CodeName/styles';
import { collapseContentBoxStyles } from 'pages/TaskTemplates/components/FormCategory/styles';

export type UseClassFieldPropertiesStylesProps = {
    isLight?: boolean;
};

export const useClassFieldPropertiesStyles = createUseStyles((theme: ThemeState) => {
    return {
        fullWidth: {
            width: '100%',
            height: '100%',
            '& .ant-spin-nested-loading, .ant-spin-container': {
                width: '100%',
                height: '100%',
            },
        },
        colWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
        },
        buttonsWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            '&> button:first-child': {
                marginRight: 12,
            },
            backgroundColor: '#fff',
            width: '100%',
            padding: 25,
            borderTop: `1px solid ${theme['border-color-base']}`,
        },
        fieldPropertiesWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .ant-row': {
                width: '100%',
                gap: '12px',
            },
        },
        noPadding: {
            padding: 0,
        },
        inputNumberWrapperForExternal: {
            ...getInputNumberWrapperStyles(theme),
            marginBottom: 4,
            '& .ant-input-number': getInputNumberStyles(theme),
            [`& #${ARROWS_WRAPPER_ID}`]: getArrowsWrapperStyles(theme),
            [`& #${ARROW_UP_ID}`]: {
                ...getArrowWrapperStyles(theme),
                alignItems: 'flex-end',
            },
            [`& #${ARROW_DOWN_ID}`]: {
                ...getArrowWrapperStyles(theme),
                alignItems: 'flex-start',
            },
        },
        externalWrapper: {
            '& .ant-collapse > .ant-collapse-item > .ant-collapse-header': {
                display: 'block',
                fontSize: 14,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                backgroundColor: 'rgb(235, 238, 244)',
                color: 'rgb(88, 108, 147) !important',
                padding: '9px 15px !important',
            },
            '& #line-text-area-wrapper': getLneTextAreaStyles(theme),
            '& #code-name-basic': textAreaWrapper(true),
            '& #code-name-detailed': textAreaWrapper(false),
            '& .ant-collapse-content-box': collapseContentBoxStyles,
            '& .ant-row': {
                margin: '4px 0',
            },
            '& .disabled #options-numbers-wrapper': {
                ...getDisabledInputStyle(theme),
                margin: 0,
                paddingRight: 4,
            },
        },
        contentWrapper: {
            height: '100%',
            overflow: 'auto',
            padding: '0 8px',
            '& .ant-collapse-header': {
                margin: '0px -8px 15px',
            },
            position: 'relative',
        },
        viewUsageWrapper: {
            '& .ant-collapse-content': {
                paddingLeft: (props: UseClassFieldPropertiesStylesProps) =>
                    props?.isLight ? 'inherit' : '16px',
            },
            '& .ant-collapse-content-box': {
                padding: '0 0 12px 0 !important',
            },
            color: theme['text-color'],
        },
        defaultBoolLabel: {
            fontWeight: 'normal',
            marginLeft: '8px',
        },
        disabledLabel: {
            opacity: 0.6,
        },
        numericInputWrapper: {
            maxWidth: 200,
        },
        viewUsageTitle: {
            textAlign: (props: UseClassFieldPropertiesStylesProps) =>
                props?.isLight ? 'left' : 'inherit',
            fontSize: '15px',
            marginTop: '16px',
            lineHeight: 1.362,
        },
        textContainer: {
            textAlign: (props: UseClassFieldPropertiesStylesProps) =>
                props?.isLight ? 'left' : 'inherit',
            marginBottom: '6px',
            lineHeight: 1.5,
            fontSize: 12,
        },
        secondaryTextContainer: {
            textAlign: (props: UseClassFieldPropertiesStylesProps) =>
                props?.isLight ? 'left' : 'inherit',
            marginBottom: '16px',
            opacity: 0.6,
            lineHeight: 1.5,
            fontSize: 12,
        },
        objectClassesNumber: {
            opacity: 0.6,
            marginLeft: '8px',
        },
        identifierLimitMessage: {
            color: theme['heading-color'],
            fontSize: 12,
            paddingTop: '8px',
            opacity: 0.6,
        },
        error: {
            color: theme['error-color'],
            opacity: 1,
        },
        bulletList: {
            listStylePosition: 'inside',
            paddingLeft: '8px',
            marginBottom: 11,
        },
        loaderWrapper: {
            padding: '32px',
            flexDirection: 'column',
            display: 'flex',
        },
        emptyMessageWrapper: {
            padding: '40px 0',
        },
        sectionWrapper: {
            '& .ant-collapse-header': {
                marginBottom: '0px !important',
            },
            '& .ant-collapse-content-box': {
                paddingBottom: '0px !important',
                paddingTop: '6px !important',
            },
        },
        sectionTitle: {
            fontSize: 14,
        },
        fieldUserOptionsWrapper: {
            position: 'relative',
        },
        avatarWrapper: {
            display: 'flex',
            paddingTop: 4,
            alignItems: 'baseline',
            paddingLeft: 8,
            textDecoration: 'none',
        },
        popoverWrapper: {
            width: 300,
            '& .ant-popover-inner-content': {
                background: theme['white-color'],
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                borderRadius: 5,
                maxHeight: 500,
                overflow: 'auto',
            },
            '& .ant-popover-arrow': {
                display: 'none',
            },
        },
        popoverContainer: {
            gap: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        tooltip: {
            minWidth: 72,
            height: 32,
            position: 'absolute',
            right: 28,
            zIndex: 1,
        },
        alert: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            border: 'none',
            marginBottom: 10,
            paddingRight: '10px !important',
            paddingLeft: '10px !important',
            '& svg.ant-alert-icon': {
                position: 'initial',
                marginRight: 9,
                color: `${theme['error-color']} !important`,
            },
            '& .ant-alert-close-icon': {
                marginLeft: 'auto',
                overflow: 'initial',
                position: 'initial',
            },
            '& span.anticon': {
                color: `${theme['error-color']} !important`,
                '& >svg': {
                    fontSize: 16,
                },
            },
            '& span.ant-alert-message': {
                color: `${theme['error-color']} !important`,
                fontSize: 11,
                fontWeight: 400,
                lineHeight: '18px',
            },
        },
        description: {
            fontSize: 12,
            fontWeight: 400,
            color: theme['disabled-color'],
            display: 'block',
            marginTop: 5,
            marginBottom: 10,
        },
        formLabelNoGap: {
            paddingBottom: '0px !important',
        },
        switchWrapper: {
            display: 'flex',
            justifyContent: 'left',
            marginBottom: 5,
        },
    };
});