import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ObjectClassCountObjectRecords,
  ObjectClassFieldObjectClasses,
} from 'utils/types/api/objectClassesFields.types';
import {
  ObjClassPermissionSetBasedUsage,
  ViewUsageDisplayType,
  ViewUsageProps,
} from './types';
import useFormatNumber from 'hooks/useFormatNumber';
import { TreeOfStrings } from './TreeOfStringsDisplay/types';
import TreeOfStringsDisplay from './TreeOfStringsDisplay';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';

const ViewUsage = ({
  usage,
  type = ViewUsageDisplayType.Name,
}: ViewUsageProps) => {
  const styles = useClassFieldPropertiesStyles();
  const formatNumberWithSeparators = useFormatNumber();

  switch (type) {
    case ViewUsageDisplayType.TreeOfStrings: {
      const treeofStringsUsage = usage as TreeOfStrings[];
      return <TreeOfStringsDisplay data={treeofStringsUsage} />;
    }
    case ViewUsageDisplayType.Number:
      const numberUsage = usage as ObjectClassFieldObjectClasses[];

      const values = usage.length
        ? {
            value: formatNumberWithSeparators(
              numberUsage[0].num_of_records_with_values
            ),
            total: formatNumberWithSeparators(numberUsage[0].num_of_records),
          }
        : { value: 0, total: 0 };

      return (
        <span>
          <FormattedMessage
            id='fieldUsage.numberOfTotal'
            defaultMessage='<b>{value}</b> of <b>{total}</b> records have values in the field.'
            values={{
              b: (...chunks: string[]) => <b>{chunks}</b>,
              ...values,
            }}
          />
        </span>
      );

    case ViewUsageDisplayType.CountOfRecords:
      const [{ num_of_records: numberOfRecords }] =
        ((usage as unknown) as ObjectClassCountObjectRecords[]) ||
        ([] as ObjectClassCountObjectRecords[]);

      if (numberOfRecords)
        return (
          <span>
            <FormattedMessage
              id='fieldUsage.countRecords'
              defaultMessage='The class has <b>{formattedCount}</b> {count, plural, one {record} other {records}}.'
              values={{
                b: (...chunks: string[]) => <b>{chunks}</b>,
                count: numberOfRecords,
                formattedCount: formatNumberWithSeparators(numberOfRecords),
              }}
            />
          </span>
        );

      return (
        <span>
          <FormattedMessage
            id='fieldUsage.countNoRecords'
            defaultMessage='This class has no records.'
          />
        </span>
      );

    case ViewUsageDisplayType.NameWithNumber:
      const list = usage as ObjectClassFieldObjectClasses[];

      return (
        <ul className={styles.bulletList}>
          {list.map(
            ({
              name,
              id,
              num_of_records_with_values: numOfRecordsWithValues,
            }) => (
              <li key={`${name}${id}`}>
                <span>{name}</span>
                <span className={styles.objectClassesNumber}>
                  ({formatNumberWithSeparators(numOfRecordsWithValues)})
                </span>
              </li>
            )
          )}
        </ul>
      );

    case ViewUsageDisplayType.ObjClassPermissionSet: {
      const list = usage as ObjClassPermissionSetBasedUsage[];

      return (
        <ul className={styles.bulletList}>
          {list.map(({ id, name, object_class_name: objClassName }) => (
            <li key={id}>
              <FormattedMessage
                id='fieldUsage.objClassPermissionSetBased'
                defaultMessage='{permissionSetName} permission set <i>({objClassName} object class)</i>'
                values={{
                  i: (...chunks: string[]) => <i>{chunks}</i>,
                  permissionSetName: name,
                  objClassName,
                }}
              />
            </li>
          ))}
        </ul>
      );
    }

    default:
      return (
        <ul className={styles.bulletList}>
          {usage.map(({ name, id }) => (
            <li key={`${name}${id}`}>{name}</li>
          ))}
        </ul>
      );
  }
};

export default ViewUsage;
