import React from 'react';
import { PlusIcon } from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import { ADD_GROUP_ASSIGNEES_BTN_TESTID } from 'utils/testIds';
import { useManageClassPermissionGroupsStyles } from './ManageClassPermissionGroups.styles';
import {
  useClassPermissionGroupAvatars,
  useClassPermissionGroupsLimit,
  useManageClassPermissionGroupsLoader,
  useUnassignClassPermissionGroup,
} from './hooks';
import List from 'components/List';
import { useIntl } from 'react-intl';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import { AvatarItem } from 'components/lib/Avatar/types';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PanelMode } from 'utils/Enums/PanelMode';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { UnassignClassPermissionGroupModal } from './components/UnassignClassPermissionGroupModal';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import clsx from 'clsx';
import { ClassPermissionGroupsLimitAlert } from './components/ClassPermissionGroupsLimitAlert';
import { Loader } from 'components/lib/Loader';

export const ManageClassPermissionGroups = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const styles = useManageClassPermissionGroupsStyles();

  const {
    [FlexLayoutWindows.ClassPermissionsOwners]: sidebarData,
  } = useSelector(getSidebarData);

  const { showLoader } = useManageClassPermissionGroupsLoader();
  const { groupAvatars } = useClassPermissionGroupAvatars();
  const {
    isGroupsLimitExceeded,
    groupAssigneesCount,
    groupAssigneesLimit,
  } = useClassPermissionGroupsLimit();
  const {
    groupToUnassign,
    isUnassigning,
    cancelUnassigning,
    setGroupToUnassign,
    unassignGroupFromClassPermission,
  } = useUnassignClassPermissionGroup();

  const disabledBtnTooltipMessage = intl.formatMessage({
    id: 'misc.maxGroupsAssignedTooltip',
    defaultMessage:
      'The maximum number of assigned user groups has been reached. Unassign user groups to assign new ones.',
  });
  const noGroupsAssignedMessage = intl.formatMessage({
    id: 'misc.noGroupsHaveBeenAssigned',
    defaultMessage: 'No user groups have been assigned',
  });

  const openAddGroupsView = () => {
    dispatch(
      setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
        ...sidebarData,
        mode: PanelMode.Adding,
      })
    );
  };

  const handleGroupItemDelete = (group: AvatarItem) => () => {
    const { id, userGroup = '' } = group;

    setGroupToUnassign({ id, name: userGroup });
  };

  if (showLoader) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size='large' />
      </div>
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <DisabledButtonTooltip
        title={disabledBtnTooltipMessage}
        showTooltip={isGroupsLimitExceeded}
      >
        <ButtonPrimary
          className={clsx({ [styles.disabledBtn]: isGroupsLimitExceeded })}
          icon={<PlusIcon size={10} />}
          disabled={isGroupsLimitExceeded}
          data-testid={ADD_GROUP_ASSIGNEES_BTN_TESTID}
          onClick={openAddGroupsView}
        />
      </DisabledButtonTooltip>

      {groupAssigneesLimit && (
        <ClassPermissionGroupsLimitAlert
          groupsCount={groupAssigneesCount}
          groupsLimit={groupAssigneesLimit}
        />
      )}

      <List<AvatarItem>
        className={styles.groupsList}
        items={groupAvatars}
        emptyDataDescription={noGroupsAssignedMessage}
        renderItem={item => (
          <GroupListElement {...item} onDelete={handleGroupItemDelete(item)} />
        )}
      />

      <UnassignClassPermissionGroupModal
        groupToUnassign={groupToUnassign}
        isLoading={isUnassigning}
        onConfirm={unassignGroupFromClassPermission}
        onCancel={cancelUnassigning}
      />
    </div>
  );
};
