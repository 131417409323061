import NoDataFoundMessage from '../NoDataFoundMessage';
import clsx from 'clsx';
import {
  VIEW_USAGE_DESC_TESTID,
  VIEW_USAGE_DESC_TO_USAGE_TESTID,
  VIEW_USAGE_LIST_ITEM_TESTID,
  VIEW_USAGE_SECTION_TESTID,
  VIEW_USAGE_TITLE_TESTID,
} from '../../utils/testIds';
import { useIntl } from 'react-intl';
import FormCategory from '../../pages/TaskTemplates/components/FormCategory';
import ViewUsage from 'components/ViewUsage';
import React, { useMemo } from 'react';
import { Loader } from '../lib/Loader';
import {
  ObjectClassCountObjectRecords,
  ObjectClassFieldObjectClasses,
} from 'utils/types/api/objectClassesFields.types';
import { createTestId } from 'utils/functions/createTestId';
import {
  ObjClassPermissionSetBasedUsage,
  ViewUsageDisplayType,
} from 'components/ViewUsage/types';
import { TreeOfStrings } from 'components/ViewUsage/TreeOfStringsDisplay/types';
import { ViewFieldUsageWindowProps } from './ViewFieldUsageWindow.types';
import { useViewFieldUsageWindowStyles } from './ViewFieldUsageWindow.styles';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';

export const ViewFieldUsageWindow: React.FC<ViewFieldUsageWindowProps> = ({
  id,
  displayConfig,
  label,
  isExternal,
  isLight = false,
  collapsible = true,
  usageLabel,
  noUsageLabel,
  isLoading,
  forceHasUsage,
  children,
}) => {
  const intl = useIntl();
  const styles = useClassFieldPropertiesStyles({ isLight });
  const viewFieldUsageWindowStyles = useViewFieldUsageWindowStyles();

  const fieldHasSomeUsage = useMemo(() => {
    return (
      !!displayConfig.flatMap(section => section.usage).length || forceHasUsage
    );
  }, [displayConfig, forceHasUsage]);

  if (id === undefined && label === undefined)
    return (
      <NoDataFoundMessage
        title={intl.formatMessage({
          id: 'misc.noDataFound',
          defaultMessage: 'No data found',
        })}
        subtitle={intl.formatMessage({
          id: 'fieldUsage.pleaseSelectAnItemToSeeUsage',
          defaultMessage: 'Please select an item to see usage.',
        })}
      />
    );

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <Loader spinning={isLoading} />
      </div>
    );

  const boldedLabel = intl.formatMessage(
    {
      id: 'fieldUsage.boldNameLabel',
      defaultMessage: 'Usage for <b>{label}</b>',
    },
    { b: (...chunks) => <b>{chunks}</b>, label }
  );

  const renderContent = (
    usage:
      | ObjectClassFieldObjectClasses[]
      | ObjectClassCountObjectRecords[]
      | TreeOfStrings[]
      | ObjClassPermissionSetBasedUsage[],
    headline?: string,
    type?: ViewUsageDisplayType
  ) => (
    <div
      className={viewFieldUsageWindowStyles.textContainer}
      data-testid={VIEW_USAGE_LIST_ITEM_TESTID}
    >
      {headline && (
        <span className={viewFieldUsageWindowStyles.headline}>{headline}</span>
      )}
      <ViewUsage {...{ usage, type }} />
    </div>
  );

  return (
    <div
      className={clsx(styles.contentWrapper, styles.viewUsageWrapper, {
        [styles.externalWrapper]: isExternal,
      })}
    >
      <p
        className={styles.viewUsageTitle}
        data-testid={VIEW_USAGE_TITLE_TESTID}
      >
        {boldedLabel}
      </p>
      {fieldHasSomeUsage ? (
        <>
          <div
            className={styles.secondaryTextContainer}
            data-testid={VIEW_USAGE_DESC_TESTID}
          >
            {usageLabel}
          </div>
          {children}
          {displayConfig?.map(
            ({ usage, title, headline, type }) =>
              !!usage.length && (
                <div
                  key={`usage-${title}`}
                  className={viewFieldUsageWindowStyles.collapseHeader}
                  data-testid={createTestId(VIEW_USAGE_SECTION_TESTID, title)}
                >
                  <FormCategory
                    noMarginTop
                    {...{
                      collapsible,
                      isLight,
                      key: title,
                      className: viewFieldUsageWindowStyles.collapseContent,
                      collapsibleContent: renderContent(usage, headline, type),
                    }}
                    key={title}
                  >
                    <span className={styles.sectionTitle}>{title}</span>
                  </FormCategory>
                </div>
              )
          )}
        </>
      ) : (
        <>
          <div
            className={styles.secondaryTextContainer}
            data-testid={VIEW_USAGE_DESC_TO_USAGE_TESTID}
          >
            {noUsageLabel}
          </div>
          {children}
        </>
      )}
    </div>
  );
};
