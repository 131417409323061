import { ExclamationMarkSquare } from 'components/Icon';
import { useObjectClassOptionsLimitReachedMessageStyles } from './ObjectClassOptionsLimitReachedMessage.styles';
import React from 'react';
import { ALERT_TYPE_TESTID } from 'utils/testIds';
import { Alert } from 'components/lib/Alert';
import { useIntl } from 'react-intl';
import { ObjectClassOptionsLimitReachedMessageProps } from './ObjectClassOptionsLimitReachedMessage.types';

export const ObjectClassOptionsLimitReachedMessage = ({
  optionsCount,
  maxOptionsCount = 100,
}: ObjectClassOptionsLimitReachedMessageProps) => {
  const intl = useIntl();
  const styles = useObjectClassOptionsLimitReachedMessageStyles();

  if (optionsCount < maxOptionsCount) return null;

  return (
    <div className={styles.wrapper} data-testid={ALERT_TYPE_TESTID}>
      <Alert
        banner
        message={intl.formatMessage(
          {
            id: 'misc.optionsLimitReached',
            defaultMessage:
              'The maximum number of options ({ itemsLimit }) has been reached.',
          },
          { itemsLimit: maxOptionsCount }
        )}
        icon={<ExclamationMarkSquare size={18} />}
        className={styles.alert}
      />
    </div>
  );
};