import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PanelMode } from 'utils/Enums/PanelMode';
import { Location } from 'history';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';

export const useAddGroupAssigneesCancellation = (
  panelKey: FlexLayoutWindows,
  hasSelectedGroups: boolean
) => {
  const dispatch = useDispatch();

  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
  } = useConfirmationModalContext();
  const { availableComponents, globalMethods } = useFlexLayoutContext();

  const { navigateTo, handleLocationChange } = useSaveChangesModal();
  const { togglePanelsPreferences } = usePanels();

  const { [panelKey]: sidebarData } = useSelector(getSidebarData);

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const isFromCancelButton = useRef(false);

  const { closeComponent: closePanel } = globalMethods;

  /**
   * Unsaved changes modal
   */
  const handleUnsavedChangesModalConfirmBtn = () => {
    setShowUnsavedChangesModal(false);

    if (isFromCancelButton.current) {
      dispatch(
        setSidebarData(panelKey, {
          ...sidebarData,
          mode: PanelMode.Manage,
        })
      );

      return;
    }

    closePanel(panelKey);
    togglePanelsPreferences(panelKey, true);

    const onPanelClose = availableComponents[panelKey]?.onClose;

    if (!onPanelClose) {
      return;
    }

    onPanelClose();
  };

  const handleUnsavedChangesModalCancelBtn = () => {
    isFromCancelButton.current = false;
    setShowUnsavedChangesModal(false);
  };

  /**
   * Custom prompt
   */
  const handleCustomPromptCancelBtn = (location?: Location) => {
    if (!location) {
      return;
    }

    handleLocationChange(`${location.pathname}${location.search}`);
  };

  /**
   * Panel's cancel button
   */
  const handlePanelCancelBtn = () => {
    if (hasSelectedGroups) {
      isFromCancelButton.current = true;
      setShowUnsavedChangesModal(true);

      return;
    }

    dispatch(
      setSidebarData(panelKey, {
        ...sidebarData,
        mode: PanelMode.Manage,
      })
    );
  };

  /**
   * Sets to "true" because panel actions are controlled by methods in the "storedModalFunctions"
   * variable
   */
  useEffect(() => {
    setShouldBeDisplayed(panelKey, true);

    return () => {
      setShouldBeDisplayed(panelKey, false);
    };
  }, [panelKey, setShouldBeDisplayed]);

  /**
   * Sets tab panel close button to show the unsaved changes modal when the user has selected some
   * groups, i.e. the form is dirty
   */
  useEffect(() => {
    if (!hasSelectedGroups) {
      setStoredModalFunctions(panelKey, undefined);

      return;
    }

    setStoredModalFunctions(panelKey, {
      callback: () => setShowUnsavedChangesModal(true),
    });
  }, [hasSelectedGroups, panelKey, setStoredModalFunctions]);

  return {
    showUnsavedChangesModal,
    navigateTo,
    handlePanelCancelBtn,
    handleCustomPromptCancelBtn,
    handleUnsavedChangesModalConfirmBtn,
    handleUnsavedChangesModalCancelBtn,
  };
};
